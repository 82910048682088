.software-skills-main-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.software-skill-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.icon {
  font-size: 3rem;
  margin-bottom: 5px;
}

.custom-icon-pytorch {
  background: url("../../assets/icons/pytorch.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-tensorflow {
  background: url("../../assets/icons/tensorflow.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-keras {
  background: url("../../assets/icons/keras.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-fastapi {
  background: url("../../assets/icons/fastapi.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-triton {
  background: url("../../assets/icons/triton.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-onnx {
  background: url("../../assets/icons/onnx.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-openai {
  background: url("../../assets/icons/openai.svg") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-kubeflow {
  background: url("../../assets/icons/kubeflow.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-gcp {
  background: url("../../assets/icons/gcp.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-mlflow {
  background: url("../../assets/icons/mlflow.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-numpy {
  background: url("../../assets/icons/numpy.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-pandas {
  background: url("../../assets/icons/pandas.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-opencv {
  background: url("../../assets/icons/opencv.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-matplotlib {
  background: url("../../assets/icons/matplotlib.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-scikit-learn {
  background: url("../../assets/icons/scikit-learn.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-huggingface {
  background: url("../../assets/icons/huggingface.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-langchain {
  background: url("../../assets/icons/langchain.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-streamlit {
  background: url("../../assets/icons/streamlit.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-githubactions {
  background: url("../../assets/icons/githubactions.png") no-repeat center
    center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

.custom-icon-dvc {
  background: url("../../assets/icons/dvc.png") no-repeat center center;
  background-size: contain;
  width: 3rem;
  height: 3rem;
}

/* Dark mode custom icons with filter */
.dark-mode .custom-icon-pytorch,
.dark-mode .custom-icon-tensorflow,
.dark-mode .custom-icon-keras,
.dark-mode .custom-icon-fastapi,
.dark-mode .custom-icon-triton,
.dark-mode .custom-icon-onnx,
.dark-mode .custom-icon-openai,
.dark-mode .custom-icon-kubeflow,
.dark-mode .custom-icon-gcp,
.dark-mode .custom-icon-mlflow,
.dark-mode .custom-icon-numpy,
.dark-mode .custom-icon-pandas,
.dark-mode .custom-icon-opencv,
.dark-mode .custom-icon-matplotlib,
.dark-mode .custom-icon-scikit-learn,
.dark-mode .custom-icon-huggingface,
.dark-mode .custom-icon-langchain,
.dark-mode .custom-icon-streamlit,
.dark-mode .custom-icon-githubactions,
.dark-mode .custom-icon-dvc {
  filter: brightness(0) invert(1);
}
